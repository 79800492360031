import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { removeHtml } from './functions';

Vue.filter('percent', (value, space = '') => {
  if (!value) value = 0;

  const rounded = Math.round(value * 100) / 100;
  return `${rounded.toFixed(2)}${space}%`;
});

Vue.filter('shortNumber', (value) => {
  if (value < 1000) {
    return _.round(value);
  }

  if (value < 1000000) {
    return `${Math.floor(value / 1000)}k`;
  }

  return `${Math.floor(value / 1000000)}M`;
});

Vue.filter('shortNumberPlus', (value) => {
  if (value < 1000000) {
    return `${Vue.options.filters.numeral(value)}`;
  }

  if (value < 1000000000) {
    return `${_.round(value / 1000000.0, 1)}M+`;
  }

  return `${Math.floor(value / 1000000000)}B+`;
});


Vue.filter('titleize', sentence => _.join(_.map(_.words(sentence), word => _.capitalize(word)), ' '));

Vue.filter('escape', value => _.escape(value));

Vue.filter('removeHtml', removeHtml);

Vue.filter('dynamicShortNumber', (value, diff) => {
  const singlePointDiff = Math.round(diff / 10);
  let scale = 1;
  let symbol = '';

  if (Math.abs(value) >= 1000000) {
    scale = 1000000;
    symbol = 'M';
  } else if (Math.abs(value) >= 1000) {
    scale = 1000;
    symbol = 'k';
  }

  const diffScale = Math.floor(scale / singlePointDiff);
  const validDecimalPlaces = (diffScale === 0 || symbol === '' ? 0 : _.clamp(diffScale.toString().length, 0, 3));

  return `${Vue.options.filters.numeral(value / scale, `0.${'0'.repeat(validDecimalPlaces)}`)}${symbol}`;
});

Vue.filter('timestamp', value => moment.utc(value * 1000).format('mm:ss'));
